import { federatedSignIn } from "../../auth/cognitoAuth";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Logo from "../../components/Logo";
import {
  DividerContainer,
  ErrorMessage,
  ForgotPassword,
  FormWrapper,
  InputGroup,
  SocialLoginContainer,
  StyledLink,
  TextContainer,
  Caption,
} from "./styled";
import BirthDetails from "../../components/BirthDetails";
import ModalPopUp from "../../components/ModalPopUp";
import {
  Body,
  Caption1,
  Footnote,
  Title2,
  Title3,
} from "../../styles/typography";
import Gradient from "../../images/gradient.png";
import { passwordValidateMultiple } from "../../util/functions";
import isBrowser from "../../util/DetectBrowser";
import { fetchAuthSession } from "@aws-amplify/auth";
import { AnimatePresence, motion } from "framer-motion";
import { posthog } from "posthog-js";
import React, { useCallback, useEffect, useState } from "react";
import { FaApple, FaGoogle } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { TbAlertTriangle, TbArrowRight, TbCheck, TbMail } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { z } from "zod";
import userApi from "../../app-api/users";
import {
  selectAmplifyError,
  userActions,
  setAmplifyError,
  selectProvider,
  selectLoginMethodLoading,
  selectIsRegisterLoading,
} from "../../app/reducers/UserSlice";
const OR = () => (
  <DividerContainer>
    <div></div>
    <Caption1>OR</Caption1>
    <div></div>
  </DividerContainer>
);

const isWebView = (() => {
  if (isBrowser) {
    const win = window;
    const ua = navigator.userAgent || navigator.vendor || win.opera;
    console.log({
      ua:
        ua.indexOf("FBAN") > -1 ||
        ua.indexOf("FBAV") > -1 ||
        ua.indexOf("Instagram") > -1,
    });
    return (
      ua.indexOf("FBAN") > -1 ||
      ua.indexOf("FBAV") > -1 ||
      ua.indexOf("Instagram") > -1
    );
  }
})();
// CHANGE BACK TO FALSE ONCE GOOGLE SIGN IN IS FIXED
export const DISABLE_GOOGLE_SIGNIN = false;

export const DISABLE_APPLE_SIGNIN = false;

export const cognitoLogin = async (values) => {
  const { signInWithEmailAndPassword } = await import("../../auth/cognitoAuth");

  await signInWithEmailAndPassword(values.email, values.password);
  await userApi.updateLoginCount();
};

export const DisabledWarning = ({ provider }) => {
  const theme = useTheme();

  return (
    <Caption1
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        alignItems: "center",
        gap: 8,
        padding: "8px 12px",
        background: `rgba(${theme.colors.pineapple100}, 0.35)`,
        borderRadius: 12,
        border: `1px solid rgb(${theme.colors.pineapple200})`,
        color: "rgba(77, 60, 0, 1)",
        margin: `${isWebView ? "16px 0" : ""}`,
      }}
    >
      <TbAlertTriangle size={24} />
      {isWebView ? (
        <span>
          Google and Apple Sign In are not available when you visit us from an
          ad. If you’d prefer to use those, please go to{" "}
          <a
            href="https://www.mystoriesmatter.com/login"
            target="_blank"
            rel="noreferrer"
            style={{
              color: `rgb(${theme.colors.accent400})`,
              textDecoration: "none",
            }}
          >
            mystoriesmatter.com/login
          </a>{" "}
          on your browser.
        </span>
      ) : (
        <span>
          We've temporarily disabled {provider} Sign In while we investigate an
          issue. We'll have it working again soon!
        </span>
      )}
    </Caption1>
  );
};

export const LoginForm = (props) => {
  const { formik, loading, result, submit, error } = props;
  const location = window.location;

  const provider = useSelector(selectProvider);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (provider === "google") {
      dispatch(
        setAmplifyError(`Looks like a My Stories Matter account exists for 
        <span style="font-weight:bolder">${localStorage.getItem(
          "email",
        )} </span>
        through Google Sign In.`),
      );
      navigate("/auth/error");
    } else if (provider === "apple") {
      dispatch(
        setAmplifyError(`Looks like a My Stories Matter account exists for 
        <span style="font-weight:bolder">${localStorage.getItem(
          "email",
        )} </span>
        through Apple Sign In.`),
      );
      navigate("/auth/error");
    }
  }, [dispatch, provider, navigate]);
  return (
    <FormWrapper onSubmit={submit}>
      <InputGroup>
        <Input
          label="Email"
          placeholder="Enter your email..."
          name={"email"}
          errorMessage={formik.errors.email}
          data-testid="EmailField"
          value={formik.values.email}
          onBlur={formik.handleBlur}
          onValueChange={formik.handleChange}
          errorCondition={formik.touched.email && formik.errors.email}
          type={"email"}
        />
        <Input
          visible="true"
          label="Password"
          placeholder="Enter your password..."
          name={"password"}
          type={"password"}
          data-testid="PasswordField"
          errorMessage={formik.errors.password}
          value={formik.values.password}
          onBlur={formik.handleBlur}
          onValueChange={formik.handleChange}
          errorCondition={formik.touched.password && formik.errors.password}
          autocomplete="current-password"
        >
          <ForgotPassword
            to="/auth/password-recovery"
            state={{ from: location.pathname }}
          >
            Forgot password?
          </ForgotPassword>
        </Input>
      </InputGroup>
      <Button
        variant="primary"
        disabled={loading || !formik.isValid ? true : false}
        data-testid="LoginButton"
        loading={loading}
        type="submit"
        key="login-button"
      >
        Login <FiArrowRight />
      </Button>
      <ErrorMessage>
        {error
          ? error
          : result && result.code !== 200 && !loading
            ? result.message
            : " "}
      </ErrorMessage>
      <TextContainer>
        <Footnote>
          Need help?{" "}
          <StyledLink
            to={"https://mystoriesmatter.com/support"}
            target="_blank"
            rel="noreferrer"
          >
            Contact support
          </StyledLink>
        </Footnote>
      </TextContainer>
    </FormWrapper>
  );
};

const ssoAuth = async (provider) => {
  const searchParams = new URLSearchParams(window.location.search);
  let searchParamsObj;
  for (const [key, val] of searchParams.entries()) {
    if (!searchParamsObj) searchParamsObj = {};
    searchParamsObj[key] = val;
  }
  if (isBrowser) {
    if (provider === "apple") {
      return federatedSignIn(
        "Apple",
        searchParamsObj
          ? encodeURIComponent(JSON.stringify(searchParamsObj))
          : searchParamsObj,
      );
    } else {
      return federatedSignIn(
        "Google",
        searchParamsObj
          ? encodeURIComponent(JSON.stringify(searchParamsObj))
          : searchParamsObj,
      );
    }
  }
};

const AuthPage = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [birthYear, setBirthYear] = React.useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isLoginMethodLoading = useSelector(selectLoginMethodLoading);
  const isSignupLoading = useSelector(selectIsRegisterLoading);

  const searchParams = new URLSearchParams(window.location.search);
  const referralID = isBrowser ? localStorage.getItem("referralID") : "";

  const redirectParam = searchParams.get("redirectUrl");

  const redirectAfterLogin = useCallback(() => {
    if (redirectParam) {
      navigate(redirectParam);
    } else {
      navigate("/app/bookcreation");
    }
  }, [redirectParam, navigate]);

  const validateEmail = (email) => {
    const emailSchema = z.string().email();
    try {
      emailSchema.parse(email);
      return true;
    } catch (error) {
      return false;
    }
  };

  const amplifyError = useSelector(selectAmplifyError);

  const [emailError, setEmailError] = React.useState(false);

  const amplifyAccountExists =
    typeof amplifyError === "string" &&
    amplifyError.startsWith(
      " Looks like a My Stories Matter account exists for",
    );

  useEffect(() => {
    if (amplifyAccountExists) {
      setFlow("email");
      const email = amplifyError.match(/\b[\w.-]+@[\w.-]+\.\w{2,4}\b/g);

      setEmail(email ? email[0] : "");
    }
  }, [amplifyAccountExists, amplifyError]);

  const theme = useTheme();

  const [response, setResponse] = React.useState(null);

  const [appleLoading, setAppleLoading] = React.useState(false);
  const [googleLoading, setGoogleLoading] = React.useState(false);
  const [emailLoading, setEmailLoading] = React.useState(false);
  const [isMigrated, setIsMigrated] = React.useState(false);

  const [coppaModalOpen, setCoppaModalOpen] = React.useState(false);

  const [errors, setErrors] = React.useState({
    password: null,
    confirmPassword: null,
    email: null,
    firstName: null,
    lastName: null,
    birthYear: null,
  });

  const [passwordReqs, setPasswordReqs] = React.useState({
    isEmpty: true,
    noLowercase: true,
    noUppercase: true,
    noNumber: true,
    noSpecial: true,
    tooShort: true,
  });

  const [flow, setFlow] = React.useState(null);

  useEffect(() => {
    fetchAuthSession()
      .then((session) => {
        if (session.tokens) {
          redirectAfterLogin();
        }
      })
      .catch(() => {});
  }, [redirectAfterLogin]);

  const clearValues = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setFirstName("");
    setLastName("");
    setBirthYear(1990);
    setErrors({
      password: null,
      confirmPassword: null,
      email: null,
      firstName: null,
      lastName: null,
      birthYear: null,
    });
    setFlow(null);
    setAppleLoading(false);
    setGoogleLoading(false);
    dispatch(setAmplifyError(null));
  };

  const googleLogin = async () => {
    setGoogleLoading(true);
    ssoAuth("google")
      .then(() => {
        clearValues();
      })
      .catch((e) => setResponse(e.message ?? e));
  };

  const appleLogin = async () => {
    setAppleLoading(true);
    await ssoAuth("apple")
      .then(() => {
        clearValues();
      })
      .catch((e) => setResponse(e));
  };

  const updateStats = async (signUpResponse) => {
    if (signUpResponse.data.uid) {
      const client = posthog;
      client.identify(signUpResponse.data.uid, {
        email: signUpResponse.data.username,
      });
      client.capture("signup-complete", {
        id: signUpResponse.data.uid,
        email: signUpResponse.data.username,
      });
    }
  };

  const emailLogin = async (signupResponse) => {
    setEmailLoading(true);
    try {
      await cognitoLogin({ email, password });
      if (signupResponse) {
        await updateStats(signupResponse);
      }

      clearValues();
      redirectAfterLogin();
    } catch (err) {
      if (
        err &&
        typeof err === "object" &&
        "name" in err &&
        typeof err.name === "string"
      ) {
        if (err.name === "NotAuthorizedException") {
          setResponse("Incorrect email or password.");
        } else if (err.name === "UserNotConfirmedException") {
          setResponse(
            <>
              Your account isn't verified yet. Please check your email for the
              confirmation link. <br />
              <br />
              <Link
                style={{
                  color: `rgb(${theme.colors.blue400})`,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() =>
                  resend().then(() => {
                    if (isBrowser) {
                      document
                        .getElementById("email-resend-success")
                        ?.style.setProperty("opacity", "1");
                    }
                    setTimeout(() => {
                      if (isBrowser) {
                        document
                          .getElementById("email-resend-success")
                          ?.style.setProperty("opacity", "0");
                      }
                    }, 2000);
                  })
                }
              >
                Resend email
              </Link>
              <TbCheck
                color={`rgb(${theme.colors.success400})`}
                style={{
                  opacity: 0,
                  scale: 2,
                  position: "relative",
                  top: 2,
                }}
                id="email-resend-success"
              />
            </>,
          );
        } else {
          setResponse(
            <>
              Something went wrong.
              <br />
              <br />
              <Link
                style={{
                  color: `rgb(${theme.colors.blue400})`,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                to={"https://mystoriesmatter.com/support"}
                targe="_blank"
                rel="noreferrer"
              >
                Contact support
              </Link>
            </>,
          );
        }
      }
    } finally {
      setEmailLoading(false);
    }
  };

  const checkLoginMethod = () => {
    const validEmail = validateEmail(email);
    if (validEmail) {
      dispatch(userActions.getUserSignUpMethod(email))
        .unwrap()
        .then((data) => {
          const providerResponse = data.data.identityProviderType ?? null;

          const isMigratedResponse = data.data.isMigrated ?? false;

          setIsMigrated(isMigratedResponse);

          if (providerResponse === "apple") {
            setFlow("apple");
          } else if (providerResponse === "google") {
            // setGoogleLoading(true);
            // ssoAuth('google');
            setFlow("google");
          } else if (providerResponse === "cognito") {
            setFlow("email");
          } else {
            setFlow("signup");
          }
        });
    } else {
      setEmailError(true);
    }
  };

  const resend = useCallback(() => {
    return dispatch(userActions.resendActivationEmail({ email: email }));
  }, [dispatch, email]);

  return (
    <Wrapper>
      <ModalPopUp
        onRequestClose={(data) => {
          setCoppaModalOpen(data);
        }}
        isOpen={coppaModalOpen}
        setIsOpen={setCoppaModalOpen}
      >
        <BirthDetails close={() => setCoppaModalOpen(false)} />
      </ModalPopUp>
      <Link to={"/"}>
        <Logo style={{ height: 32, marginBottom: 24 }} />
      </Link>
      {/* DEBUG FLOW STATUS */}
      {/* <Caption1 style={{ position: 'absolute', top: 8, right: 8 }}>
        {flow ?? 'null'}
      </Caption1> */}
      <img
        src={Gradient}
        alt="Gradient"
        style={{
          position: "fixed",
          inset: 0,
          opacity: 0.5,
          zIndex: -1,
          width: "100%",
          height: "100%",
        }}
      />

      {/* NOTIF BANNER */}
      <motion.div
        initial={false}
        animate={{
          height:
            flow === "apple" ||
            flow === "google" ||
            flow === "signup" ||
            (amplifyAccountExists && flow === "email")
              ? "auto"
              : 0,
        }}
      >
        <AnimatePresence>
          {(flow === "apple" ||
            flow === "google" ||
            (amplifyAccountExists && flow === "email")) && (
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <Footnote
                as={motion.span}
                style={{
                  color: `rgb(${theme.colors.blue600})`,
                  marginTop: 12,
                  marginBottom: 32,
                  textAlign: "center",
                  padding: "12px 24px",
                  background: `rgba(${theme.colors.blue100}, .5)`,
                  borderRadius: 8,
                  border: `2px solid rgba(${theme.colors.blue600}, .1)`,
                  boxShadow: `0px 4px 8px rgba(${theme.colors.blue400}, .1)`,
                }}
              >
                {flow === "apple" ? (
                  "You previously signed in with Apple"
                ) : flow === "google" ? (
                  "You previously signed in with Google"
                ) : amplifyAccountExists ? (
                  "You previously signed in with this email address. Please enter your password to continue."
                ) : (
                  <>You don’t have an account yet.</>
                )}
              </Footnote>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {flow === "signup" && (
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              style={{
                display: "grid",
                width: "100%",
                justifyContent: "start",
                alignItems: "start",
                paddingBottom: 48,
                color: `rgb(${theme.colors.neutral400})`,
              }}
            >
              <Title2 style={{ color: `rgb(${theme.colors.neutral600})` }}>
                Welcome!
              </Title2>
              <Body style={{ marginTop: 12 }}>
                How would you like to create your{" "}
                <span style={{ whiteSpace: "nowrap" }}>My Stories Matter</span>{" "}
                account?
              </Body>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      {/* SOCIAL BUTTONS */}
      <motion.div
        initial={false}
        animate={{
          height:
            flow !== null && flow !== "signup" && flow !== "pre-email"
              ? 0
              : "auto",
          opacity:
            flow !== null && flow !== "signup" && flow !== "pre-email" ? 0 : 1,
        }}
      >
        <AnimatePresence>
          {(flow === null || flow === "signup" || flow === "pre-email") && (
            <motion.div
              style={{
                display: "grid",
                gridAutoFlow: "row",
                gap: 12,
                overflow: "hidden",
                alignContent: "end",
              }}
              initial={false}
              exit={{ opacity: 0 }}
              animate={{
                opacity:
                  flow === null || flow === "signup" || flow === "pre-email"
                    ? 1
                    : 0,
              }}
            >
              {!isWebView && (
                <Button
                  loading={googleLoading}
                  disabled={DISABLE_GOOGLE_SIGNIN}
                  onButtonClick={() => {
                    setGoogleLoading(true);
                    setResponse(null);
                    ssoAuth("google")
                      .catch((e) => {
                        setResponse(e.message ?? e);
                      })
                      .finally(() => setGoogleLoading(false));
                  }}
                  variant="secondary"
                >
                  <FaGoogle />
                  {flow === "signup" ? "Sign up" : "Continue"} with Google
                </Button>
              )}

              {!isWebView && (
                <Button
                  loading={appleLoading}
                  disabled={DISABLE_APPLE_SIGNIN}
                  onButtonClick={() => {
                    setAppleLoading(true);
                    setResponse(null);
                    ssoAuth("apple")
                      .catch((e) => setResponse(e.message ?? e))
                      .finally(() => setAppleLoading(false));
                  }}
                  variant="secondary"
                >
                  <FaApple />
                  {flow === "signup" ? "Sign up" : "Continue"} with Apple
                </Button>
              )}
              {!isWebView && (
                <Footnote
                  style={{
                    justifySelf: "center",
                    color: `rgb(${theme.colors.neutral200})`,
                    marginTop: 24,
                    marginBottom: 12,
                  }}
                >
                  OR
                </Footnote>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      {/* PASSWORD REQUIREMENTS */}
      <motion.div
        animate={{
          height: flow === "signup-email-password" ? "auto" : 0,
        }}
      >
        <AnimatePresence>
          {flow === "signup-email-password" && (
            <motion.div
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              style={{ display: "grid", gridAutoFlow: "row" }}
            >
              <Title3 style={{ marginBottom: 12, display: "block" }}>
                Create a password
              </Title3>
              <Footnote style={{ marginBottom: 8, display: "block" }}>
                Your password must include:
              </Footnote>
              <PasswordReqsContainer
                style={{
                  display: "grid",
                  gridAutoFlow: "row",
                  gap: 0,
                  justifyContent: "stretch",
                  width: "100%",
                }}
              >
                <Footnote
                  data-req-fulfilled={passwordReqs.noUppercase === false}
                  as={"li"}
                >
                  <span>an uppercase letter</span>
                  <motion.div
                    animate={{
                      opacity: passwordReqs.noUppercase === false ? 1 : 0,
                      scale: passwordReqs.noUppercase === false ? 1.25 : 1,
                      y: passwordReqs.noUppercase === false ? 2 : 4,
                    }}
                  >
                    <TbCheck color={`rgb(${theme.colors.cucumber400})`} />
                  </motion.div>
                </Footnote>
                <Footnote
                  data-req-fulfilled={passwordReqs.noLowercase === false}
                  as={"li"}
                >
                  <span>a lowercase letter</span>
                  <motion.div
                    animate={{
                      opacity: passwordReqs.noLowercase === false ? 1 : 0,
                      scale: passwordReqs.noLowercase === false ? 1.25 : 1,
                      y: passwordReqs.noLowercase === false ? 2 : 4,
                    }}
                  >
                    <TbCheck color={`rgb(${theme.colors.cucumber400})`} />
                  </motion.div>
                </Footnote>
                <Footnote
                  data-req-fulfilled={passwordReqs.noNumber === false}
                  as={"li"}
                >
                  <span>a number</span>
                  <motion.div
                    animate={{
                      opacity: passwordReqs.noNumber === false ? 1 : 0,
                      scale: passwordReqs.noNumber === false ? 1.25 : 1,
                      y: passwordReqs.noNumber === false ? 2 : 4,
                    }}
                  >
                    <TbCheck color={`rgb(${theme.colors.cucumber400})`} />
                  </motion.div>
                </Footnote>
                <Footnote
                  data-req-fulfilled={passwordReqs.noSpecial === false}
                  as={"li"}
                >
                  <span>a special character (!@#$%&*)</span>
                  <motion.div
                    animate={{
                      opacity: passwordReqs.noSpecial === false ? 1 : 0,
                      scale: passwordReqs.noSpecial === false ? 1.25 : 1,
                      y: passwordReqs.noSpecial === false ? 2 : 4,
                    }}
                  >
                    <TbCheck color={`rgb(${theme.colors.cucumber400})`} />
                  </motion.div>
                </Footnote>
                <Footnote
                  data-req-fulfilled={passwordReqs.tooShort === false}
                  as={"li"}
                >
                  <span>at least 8 characters</span>
                  <motion.div
                    animate={{
                      opacity: passwordReqs.tooShort === false ? 1 : 0,
                      scale: passwordReqs.tooShort === false ? 1.25 : 1,
                      y: passwordReqs.tooShort === false ? 2 : 4,
                    }}
                  >
                    <TbCheck color={`rgb(${theme.colors.cucumber400})`} />
                  </motion.div>
                </Footnote>
              </PasswordReqsContainer>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      {/* INPUTS */}
      <form>
        {/* EMAIL INPUT */}
        <motion.div
          initial={false}
          style={{
            overflow: "hidden",
          }}
          animate={{
            height:
              flow === "pre-email" ||
              flow === "google" ||
              flow === "apple" ||
              flow === "email" ||
              flow === "signup"
                ? "auto"
                : 0,
            opacity:
              flow === "pre-email" ||
              flow === "google" ||
              flow === "apple" ||
              flow === "email" ||
              flow === "signup"
                ? 1
                : 0,
          }}
        >
          <AnimatePresence>
            <motion.div
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              style={{ paddingBottom: 16 }}
            >
              <Input
                value={email}
                autofocus
                type="email"
                name="email"
                errorCondition={emailError}
                label="Email"
                placeholder="Enter your email..."
                onBlur={() => {}}
                disabled={flow !== "pre-email"}
                errorMessage={
                  email === "" ? "Please enter your email" : "Invalid email"
                }
                onValueChange={(e) => {
                  setEmailError(false);
                  if (flow === "pre-email") {
                    setEmail(e.target.value);
                  }
                }}
                autocomplete="username"
              />
            </motion.div>
          </AnimatePresence>
        </motion.div>

        {/* DETAILS INPUTS */}
        <motion.div
          animate={{
            height: flow === "signup-email-details" ? "auto" : 0,
          }}
        >
          <AnimatePresence>
            {flow === "signup-email-details" && (
              <motion.div
                style={{
                  paddingTop: 24,
                  paddingBottom: 24,
                }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
              >
                <Input
                  visible="true"
                  label="First Name"
                  placeholder="Enter your first name..."
                  name={"first-name"}
                  type={"text"}
                  disabled={flow !== "signup-email-details"}
                  data-testid="PasswordField"
                  errorMessage={errors.firstName}
                  value={firstName}
                  onBlur={() => {}}
                  onValueChange={(e) => {
                    setResponse(null);
                    setErrors((errors) => ({
                      ...errors,
                      firstName: null,
                    }));
                    setFirstName(e.target.value);
                  }}
                  errorCondition={errors.firstName}
                  autofocus
                  autocomplete={"given-name"}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
        <motion.div
          animate={{
            height: flow === "signup-email-details" ? "auto" : 0,
          }}
        >
          <AnimatePresence>
            {flow === "signup-email-details" && (
              <motion.div
                style={{
                  paddingTop: 24,
                  paddingBottom: 24,
                }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
              >
                <Input
                  visible="true"
                  label="Last Name"
                  placeholder="Enter your last name..."
                  name={"last-name"}
                  type={"text"}
                  disabled={flow !== "signup-email-details"}
                  data-testid="PasswordField"
                  errorMessage={errors.lastName}
                  value={lastName}
                  onBlur={() => {}}
                  onValueChange={(e) => {
                    setResponse(null);
                    setErrors((errors) => ({
                      ...errors,
                      lastName: null,
                    }));
                    setLastName(e.target.value);
                  }}
                  errorCondition={errors.lastName}
                  autofocus
                  autocomplete={"family-name"}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
        <motion.div
          animate={{
            height: flow === "signup-email-details" ? "auto" : 0,
          }}
        >
          <AnimatePresence>
            {flow === "signup-email-details" && (
              <motion.div
                style={{
                  paddingTop: 24,
                  paddingBottom: 24,
                }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
              >
                <Input
                  visible="true"
                  label="Birth Year"
                  placeholder="Enter your birth year..."
                  name={"birth-year"}
                  type={"number"}
                  disabled={flow !== "signup-email-details"}
                  data-testid="PasswordField"
                  errorMessage={errors.birthYear}
                  value={birthYear}
                  onBlur={() => {
                    setErrors((errors) => ({
                      ...errors,
                      birthYear: null,
                    }));
                    if (birthYear && birthYear < 1900) {
                      setErrors((errors) => ({
                        ...errors,
                        birthYear: "Please enter a valid birth year",
                      }));
                    } else if (
                      birthYear &&
                      birthYear > new Date().getFullYear() - 14
                    ) {
                      setErrors((errors) => ({
                        ...errors,
                        birthYear: "You must be at least 14 years old",
                      }));
                    }
                  }}
                  onValueChange={(e) => {
                    setResponse(null);
                    setErrors((errors) => ({
                      ...errors,
                      birthYear: null,
                    }));
                    setBirthYear(parseInt(e.target.value ?? "1990"));
                  }}
                  errorCondition={errors.birthYear}
                  autofocus
                  autocomplete={"family-name"}
                >
                  <Caption
                    onClick={() => {
                      setCoppaModalOpen(true);
                    }}
                  >
                    Why do we ask this?
                  </Caption>
                </Input>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        {/* PASSWORD INPUT */}
        <motion.div
          animate={{
            height:
              flow === "email" || flow === "signup-email-password" ? "auto" : 0,
          }}
        >
          <AnimatePresence>
            {(flow === "email" || flow === "signup-email-password") && (
              <motion.div
                style={{
                  paddingTop: 24,
                  paddingBottom: 24,
                }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
              >
                <Input
                  visible="true"
                  label="Password"
                  placeholder="Enter your password..."
                  name={"password"}
                  type={"password"}
                  disabled={
                    flow !== "email" && flow !== "signup-email-password"
                  }
                  data-testid="PasswordField"
                  errorMessage={errors.password}
                  value={password}
                  onBlur={() => {
                    if (flow === "signup-email-password") {
                      setPasswordReqs(passwordValidateMultiple(password));
                      if (
                        Object.values(passwordReqs).some((val) => val === true)
                      ) {
                        setErrors((errors) => ({
                          ...errors,
                          password: "Password does not meet requirements.",
                        }));
                      } else {
                        setErrors((errors) => ({
                          ...errors,
                          password: null,
                          confirmPassword: null,
                        }));
                      }
                    }
                  }}
                  onValueChange={(e) => {
                    setErrors((errors) => ({
                      ...errors,
                      password: null,
                      confirmPassword: null,
                    }));
                    setResponse(null);
                    setPasswordReqs(passwordValidateMultiple(e.target.value));
                    setPassword(e.target.value);
                  }}
                  errorCondition={errors.password}
                  autofocus
                  autocomplete={
                    flow === "signup-email-password"
                      ? "new-password"
                      : "current-password"
                  }
                >
                  {flow === "email" && (
                    <ForgotPassword
                      to="/auth/password-recovery"
                      state={{ from: window.location.pathname }}
                    >
                      Forgot password?
                    </ForgotPassword>
                  )}
                </Input>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        {/* CONFIRM PASSWORD INPUT */}
        <motion.div
          animate={{ height: flow === "signup-email-password" ? "auto" : 0 }}
        >
          <AnimatePresence>
            {flow === "signup-email-password" && (
              <motion.div
                style={{
                  paddingTop: 24,
                  paddingBottom: 24,
                }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
              >
                <Input
                  visible="true"
                  label="Confirm password"
                  placeholder="Enter your password..."
                  name={"confirm-password"}
                  type={"password"}
                  disabled={flow !== "signup-email-password"}
                  data-testid="ConfirmPasswordField"
                  errorMessage={errors.confirmPassword}
                  value={confirmPassword}
                  onBlur={() => {
                    if (password !== confirmPassword) {
                      setErrors((errors) => ({
                        ...errors,
                        confirmPassword: "Passwords do not match.",
                      }));
                    } else {
                      setErrors((errors) => ({
                        ...errors,
                        confirmPassword: null,
                      }));
                    }
                  }}
                  onValueChange={(e) => {
                    setErrors((errors) => ({
                      ...errors,
                      confirmPassword: null,
                      password: null,
                    }));
                    setConfirmPassword(e.target.value);
                  }}
                  errorCondition={errors.confirmPassword !== null}
                  autofocus
                  autocomplete="new-password"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        {/* ACTION BUTTONS */}
        <motion.div
          style={{
            display: "grid",
            gap: 12,
            paddingTop: 16,
            gridAutoFlow: "row",
          }}
          animate={{
            gridTemplateColumns: flow && flow !== "signup" ? "1fr 1fr" : "1fr",
          }}
        >
          <Button
            style={{
              gridColumn: flow === "signup" ? "unset" : flow !== null ? 2 : 1,
              gridRow: 1,
            }}
            type="submit"
            variant={
              flow === "email" ||
              flow === "pre-email" ||
              flow === "signup-email-details" ||
              flow === "signup-email-password" ||
              flow === "signup"
                ? "primary"
                : "secondary"
            }
            loading={
              isLoginMethodLoading ||
              appleLoading ||
              googleLoading ||
              emailLoading ||
              isSignupLoading
            }
            disabled={
              isLoginMethodLoading ||
              appleLoading ||
              googleLoading ||
              emailLoading ||
              isSignupLoading
            }
            onButtonClick={async (e) => {
              e.preventDefault();

              setResponse(null);
              if (flow === null) {
                setFlow("pre-email");
              } else {
                if (flow === "google") {
                  await googleLogin();
                } else if (flow === "apple") {
                  await appleLogin();
                } else if (flow === "email") {
                  if (password === "") {
                    setErrors((errors) => ({
                      ...errors,
                      password: "Please enter your password",
                    }));
                  } else {
                    setEmailLoading(true);
                    if (!isMigrated) {
                      await emailLogin();
                    } else {
                      // await migratedUserLogin();
                    }
                  }
                } else if (flow === "signup") {
                  setFlow("signup-email-details");
                } else if (flow === "signup-email-details") {
                  if (
                    firstName === "" ||
                    lastName === "" ||
                    (birthYear && birthYear < 1900) ||
                    (birthYear && birthYear > new Date().getFullYear() - 14)
                  ) {
                    if (firstName === "") {
                      setErrors((errors) => ({
                        ...errors,
                        firstName: "Please enter your first name",
                      }));
                    }
                    if (lastName === "") {
                      setErrors((errors) => ({
                        ...errors,
                        lastName: "Please enter your last name",
                      }));
                    }
                    if (!birthYear || birthYear < 1900) {
                      setErrors((errors) => ({
                        ...errors,
                        birthYear: "Please enter a valid year",
                      }));
                    }
                    if (
                      birthYear &&
                      birthYear > new Date().getFullYear() - 14
                    ) {
                      setErrors((errors) => ({
                        ...errors,
                        birthYear: "You must be at least 14 years old",
                      }));
                    }
                  } else {
                    setFlow("signup-email-password");
                  }
                } else if (flow === "signup-email-password") {
                  const passErr =
                    password === "" ||
                    Object.values(passwordReqs).some((val) => val === true);

                  const confPassErr = password !== confirmPassword;

                  if (passErr || confPassErr) {
                    if (passErr) {
                      setErrors((errors) => ({
                        ...errors,
                        password: "Password does not meet requirements.",
                      }));
                    }

                    if (confPassErr) {
                      setErrors((errors) => ({
                        ...errors,
                        confirmPassword: "Passwords do not match.",
                      }));
                    }
                  } else {
                    await dispatch(
                      userActions.register({
                        email: email,
                        password: password,
                        firstName: firstName,
                        lastName: lastName,
                        birthYear: birthYear,
                        authRedirectURL: window.location.hostname,
                      }),
                    )
                      .unwrap()
                      .then((res) => {
                        emailLogin(res);
                      })
                      .catch((e) => {
                        setResponse(
                          "Something went wrong. Please try again later.",
                        );
                      })
                      .finally(() => {
                        setEmailLoading(false);
                      });
                  }
                } else {
                  checkLoginMethod();
                }
              }
            }}
          >
            {flow === "google" ? (
              <>
                <FaGoogle /> Continue <TbArrowRight />
              </>
            ) : flow === "apple" ? (
              <>
                <FaApple /> Continue <TbArrowRight />
              </>
            ) : flow === "signup" ? (
              <>
                <TbMail /> Sign up with email
              </>
            ) : flow === "pre-email" ||
              flow === "email" ||
              flow === "signup-email-password" ||
              flow === "signup-email-details" ? (
              <>
                Continue <TbArrowRight />
              </>
            ) : (
              <>
                <TbMail /> Continue with email
              </>
            )}
          </Button>
          {flow && (
            <Button
              style={{
                gridColumn: 1,
                gridRow: flow === "signup" ? 2 : 1,
              }}
              onButtonClick={(e) => {
                e.preventDefault();

                dispatch(setAmplifyError(null));

                if (email !== "" && flow !== "pre-email") {
                  setFlow("pre-email");
                } else {
                  setFlow(null);
                }
                setResponse(null);
              }}
              variant="tertiary"
            >
              Cancel
            </Button>
          )}
        </motion.div>
      </form>
      <AnimatePresence>
        {response && (
          <motion.div
            animate={{ height: "auto", opacity: 1 }}
            initial={{ height: 0, opacity: 0 }}
            exit={{ height: 0, opacity: 0 }}
            style={{
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ErrorMessage
              style={{
                paddingTop: 24,
                paddingBottom: 0,
                boxSizing: "border-box",
                height: "auto",
                display: "inline-block",
              }}
            >
              {response}
            </ErrorMessage>
          </motion.div>
        )}
        {/* {amplifyError && (
          <motion.div
            animate={{ height: 'auto', opacity: 1 }}
            initial={{ height: 0, opacity: 0 }}
            exit={{ height: 0, opacity: 0 }}
            style={{
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ErrorMessage
              style={{
                paddingTop: 24,
                paddingBottom: 0,
                boxSizing: 'border-box',
                height: 'auto',
                display: 'inline-block',
              }}
            >
              {amplifyError}
            </ErrorMessage>
          </motion.div>
        )} */}
      </AnimatePresence>
      {isWebView && <DisabledWarning provider="Google" />}
    </Wrapper>
  );
};

export default AuthPage;

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  margin: 0 auto;
  gap: 0;
  margin-top: 64px;
  border-radius: 16px;
  padding: 32px 48px;
  max-width: 480px;
  width: calc(100vw - 16px);
  background: rgb(${(props) => props.theme.colors.white});
  /* border: 0.1px solid rgb(${(props) => props.theme.colors.neutral150}); */
  position: relative;
  box-sizing: border-box;
  transition: padding 0.2s ease-in-out;

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -4px;
    background: rgba(${(props) => props.theme.colors.blue600}, 0.1);
    filter: blur(16px);
    border-radius: 16px;
    z-index: -1;
  }
  :before {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -8px;
    background: rgba(${(props) => props.theme.colors.blue600}, 0.1);
    filter: blur(4px);
    border-radius: 16px;
    z-index: -1;
  }

  @media screen and (max-width: 800px) {
    padding: 24px 32px;
  }

  @media screen and (max-width: 400px) {
    padding: 16px 16px;
  }
`;

const PasswordReqsContainer = styled.ul`
  li {
    padding: 4px 8px;
    background-color: rgba(${(props) => props.theme.colors.pineapple100}, 0.5);
    color: rgb(${(props) => props.theme.colors.pineappleDark});
    border-radius: 8px;
    transition:
      background-color 0.1s ease-in-out,
      border-radius 0.1s ease-in-out;

    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }

  /* li:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  li:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  } */

  /* li[data-req-fulfilled='true'] + li:not([data-req-fulfilled='true']) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  } */

  li[data-req-fulfilled="false"]:has(+ li[data-req-fulfilled="false"]) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  li[data-req-fulfilled="false"] + li[data-req-fulfilled="false"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  li[data-req-fulfilled="true"] {
    color: rgb(${(props) => props.theme.colors.neutral400});
    background: none;
    text-decoration: line-through;
  }

  li[data-req-fulfilled="true"] > *:not(svg) {
    opacity: 0.5;
  }
`;
