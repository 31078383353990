import React from "react";
import { SpinnerCircular } from "spinners-react";
import { useTheme } from "styled-components";
import { Body } from "../../styles/typography";

const AuthCallBack = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "16px",
        padding: "0 24px",
      }}
    >
      <SpinnerCircular
        size={48}
        thickness={200}
        aria-label="Loading Spinner"
        speed={100}
        color={`rgba(${theme.colors.neutral200}, 1)`}
        secondaryColor={`rgba(${theme.colors.neutral100}, 1)`}
        style={{
          justifySelf: "center",
        }}
      />
      <Body style={{ textAlign: "center" }}>
        Please wait while we sign you in.
      </Body>
    </div>
  );
};

export default AuthCallBack;
