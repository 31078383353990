import { Caption1, Footnote, Caption2 } from "../../styles/typography";
import { Link } from "react-router-dom";
import styled from "styled-components";
export const DividerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 8px;
  padding: 0 8px;
  align-items: center;

  > ${Caption1} {
    text-transform: uppercase;
    color: rgb(${(props) => props.theme.colors.neutral200});
  }

  > div {
    height: 2px;
    background: rgb(${(props) => props.theme.colors.neutral100});
  }
`;

export const ErrorMessage = styled(Caption1)`
  color: rgb(${(props) => props.theme.colors.error400});
  text-align: center;
  height: 1em;
  padding-bottom: 10px;
`;
export const ForgotPassword = styled(Link)`
  color: rgba(${(props) => props.theme.colors.neutral200});
  text-align: right;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 125%;
  text-align: right;
  letter-spacing: -0.05em;
  max-width: fit-content;
  margin: 2px 4px;
`;
export const FormWrapper = styled.form`
  display: grid;
  grid-auto-flow: row;
  gap: 32px;
  justify-content: stretch;
  box-sizing: border-box;
  width: 100%;
  max-width: 480px;
`;
export const InputGroup = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 24px;
`;
export const SocialLoginContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
  justify-content: stretch;
  box-sizing: border-box;
  width: 100%;
  max-width: 480px;
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: rgb(${(props) => props.theme.colors.blue400});
`;
export const TextContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 12px;
  color: rgb(${(props) => props.theme.colors.neutral200});

  > * {
    text-align: center;
  }

  span:not(${Footnote}) {
    white-space: nowrap;
  }
`;
export const Caption = styled(Caption2)`
  float: left;
  cursor: pointer;
  color: rgb(${(props) => props.theme.colors.neutral200});
  // padding-top: 8px;
`;
