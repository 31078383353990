import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";

const StyledLogoutButton = styled(Button)`
  min-width: ${(props) => (props.minWidth ? props.minWidth : "fit-content")};
  align-self: flex-start;
  @media screen and (max-width: 599px) {
    align-self: center;
    min-width: ${(props) => (props.minWidth ? "272px" : "195px")};
    width: ${(props) => (props.minWidth ? "100%" : "90%")};
  }
`;

const LogoutButton = ({ minWidth }) => {
  const navigate = useNavigate();
  const logoutUser = () => {
    navigate("/logout");
  };
  return (
    <>
      <StyledLogoutButton
        minWidth={minWidth}
        variant="warning"
        onClick={logoutUser}
      >
        Log out
      </StyledLogoutButton>
    </>
  );
};

export default LogoutButton;
