import { Hub } from "@aws-amplify/core";
import { posthog } from "posthog-js";
import React, { useEffect } from "react";
import { isProd } from "../util/functions";
import { getCurrentUser } from "@aws-amplify/auth";

export const PosthogListener = () => {
  useEffect(() => {
    if (!isProd) {
      posthog.debug(true);
    }
    const posthogListener = Hub.listen("auth", ({ payload }) => {
      const { event, data } = payload;
      switch (event) {
        case "signedIn": {
          if (data.signInDetails) {
            posthog.identify(data.username, {
              email: data?.signInDetails.loginId,
            });
          }
          break;
        }

        case "customOAuthState": {
          if (data.signInDetails) {
            posthog.identify(data.username, {
              email: data?.signInDetails.loginId,
            });
          }
          break;
        }

        case "signInWithRedirect": {
          if (data.signInDetails) {
            posthog.identify(data.username, {
              email: data?.signInDetails.loginId,
            });
          }
          break;
        }

        case "oAuthSignOut": {
          posthog.reset();
          break;
        }
        case "signedOut": {
          posthog.reset();
          break;
        }
        default:
          break;
      }
    });
    getCurrentUser()
      .then((user) => {
        if (user) {
          if (user.signInDetails) {
            posthog.identify(user.username, {
              email: user.signInDetails.loginId,
            });
          }
        }
      })
      .catch((err) => {
        // user is not authenticated
      });

    return () => {
      posthogListener();
    };
  }, []);

  return <></>;
};
