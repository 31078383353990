import styled, { css } from "styled-components";
import { Callout } from "../styles/typography";
import { Link } from "react-router-dom";
const ContainerStyles = css`
  min-height: 30px;
  display: flex;
  gap: 4px;
  padding: 2px 6px 2px 6px;
  border-radius: 4px;
  border: 2px solid rgb(${(props) => props.theme.colors.neutral150});
  ${Callout} {
    color: rgb(${(props) => props.theme.colors.neutral200});
    text-align: center;
    font-weight: 500;
    line-height: 17.5px;
    font-size: 14px;
  }
  background-color: rgb(${(props) => props.theme.colors.offWhite});
  align-items: center;

  :link {
    text-decoration: none;
  }
  svg {
    width: 21px;
    height: 21px;
    min-width: 21px;
    min-height: 21px;
    color: rgb(${(props) => props.theme.colors.neutral200});
  }

  @media (hover: hover) {
    :hover {
      border: 2px solid rgb(${(props) => props.theme.colors.neutral200});
    }
  }
`;
const Container = styled.div`
  ${ContainerStyles}
`;

const StyledLink = styled(Link)`
  ${ContainerStyles}
`;
const SubAction = ({ iconLeft, iconRight, onAction, to, children }) => {
  if (to) {
    return (
      <StyledLink to={to}>
        {iconLeft && iconLeft}
        <Callout>{children}</Callout>
        {iconRight && iconRight}
      </StyledLink>
    );
  }
  return (
    <Container onClick={onAction}>
      {iconLeft && iconLeft}
      <Callout>{children}</Callout>
      {iconRight && iconRight}
    </Container>
  );
};

export default SubAction;
