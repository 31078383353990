import styled from "styled-components";
import theme from "../../../styles/colors";
import { Body, Bold, Title1 } from "../../../styles/typography";

// style for subcard is not clear by figma so it may be differ cause of uncatchable card
const StyledSubCard = styled.div`
  border-radius: 16px;
  border: 2px solid ${theme.sectionBorder};
  padding: ${(props) => props.padding};
`;
const SubCardTitle = styled(Title1)`
  line-height: 125%;
  color: ${`rgb(${theme.colors.neutral400})`};
`;
const SubCardHeading = styled(Bold)`
  line-height: 125%;
  text-align: center;
  color: ${`rgb(${theme.colors.neutral400})`};
`;
const SubCardDescription = styled(Body)`
  line-height: 125%;
  text-align: center;
  color: ${`rgb(${theme.colors.neutral200})`};
`;
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: ${(props) => props.margin};
`;

const SubCard = ({ title, heading, description }) => {
  return (
    <StyledSubCard padding="16px">
      <CardHeader>
        <SubCardTitle>{title}</SubCardTitle>
      </CardHeader>
      <CardHeader>
        <SubCardHeading>{heading}</SubCardHeading>
      </CardHeader>
      <CardHeader>
        <SubCardDescription margin="4px 0px">{description}</SubCardDescription>
      </CardHeader>
    </StyledSubCard>
  );
};

export default SubCard;
