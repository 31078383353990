import { fetchAuthSession } from "@aws-amplify/auth";

export const fetchApi = {
  post: async (url, body, contentType = "application/json") => {
    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken?.toString() ?? "";
      const refreshToken = session.tokens?.accessToken?.toString() ?? "";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: idToken,
          refreshToken,
          platform: "2",
          appType: "0",
          "Accept-Language": "en",
          "content-type": contentType,
        },
        body: body ? JSON.stringify(body) : undefined,
      });
      if (!response.ok) {
        return Promise.reject(response);
      }
      const json = await response.json();

      return json;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  delete: async (url, contentType = "application/json") => {
    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken?.toString() ?? "";
      const refreshToken = session.tokens?.accessToken?.toString() ?? "";
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: idToken,
          refreshToken,
          platform: "2",
          appType: "0",
          "Accept-Language": "en",
          "content-type": contentType,
        },
      });
      if (!response.ok) {
        return Promise.reject(response);
      }
      const json = await response.json();

      return json;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  get: async (url, contentType = "application/json") => {
    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken?.toString() ?? "";
      const refreshToken = session.tokens?.accessToken?.toString() ?? "";
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: idToken,
          refreshToken,
          platform: "2",
          appType: "0",
          "Accept-Language": "en",
          "content-type": contentType,
        },
      });
      if (!response.ok) {
        return Promise.reject(response);
      }
      const json = await response.json();

      return json;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  patch: async (url, body, contentType = "application/json") => {
    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken?.toString() ?? "";
      const refreshToken = session.tokens?.accessToken?.toString() ?? "";
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: idToken,
          refreshToken,
          platform: "2",
          appType: "0",
          "Accept-Language": "en",
          "content-type": contentType,
        },
        body: body ? JSON.stringify(body) : undefined,
      });
      if (!response.ok) {
        return Promise.reject(response);
      }
      const json = await response.json();

      return json;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  put: async (url, body, contentType = "application/json") => {
    try {
      const session = await fetchAuthSession();
      const idToken = session.tokens?.idToken?.toString() ?? "";
      const refreshToken = session.tokens?.accessToken?.toString() ?? "";
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: idToken,
          refreshToken,
          platform: "2",
          appType: "0",
          "Accept-Language": "en",
          "content-type": contentType,
        },
        body: body ? JSON.stringify(body) : undefined,
      });
      if (!response.ok) {
        return Promise.reject(response);
      }
      const json = await response.json();

      return json;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
