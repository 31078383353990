import { TbX } from "react-icons/tb";
import { Title2, Body } from "../styles/typography";
import styled from "styled-components";

export const MainContainer = styled.div`
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  @media screen and (max-width: 600px) {
    padding-top: 10vh;
    height: 100vh;
    max-width: 100vw;
  }
`;
export const TitleWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 16fr 1fr;
  @media screen and (max-width: 800px) {
    grid-auto-columns: 26fr 2fr;
  }
  svg {
    color: rgb(${(props) => props.theme.colors.neutral200});
    height: 20px;
    width: 20px;
    padding: 8px;
    cursor: pointer;
  }
`;
export const Title = styled(Title2)`
  padding: 24px 0px 16px 32px;
`;
export const Description = styled(Body)`
  margin: 0px 32px 0px 32px;
  color: rgb(${(props) => props.theme.colors.neutral400});
`;
export const FooterDescription = styled(Body)`
  margin: 16px 32px 32px 32px;
  color: rgb(${(props) => props.theme.colors.neutral400});
`;
export const StyledSpan = styled.span`
  color: rgb(${(props) => props.theme.colors.blue200});
  text-decoration: underline;
`;
const BirthDetails = ({ close }) => {
  return (
    <MainContainer>
      <TitleWrapper>
        <Title>Why do we ask for your birth year?</Title>
        <TbX onClick={close} />
      </TitleWrapper>
      <Description>
        This helps us ensure that we are abiding by{" "}
        <StyledSpan
          as="a"
          href="https://www.ftc.gov/legal-library/browse/rules/childrens-online-privacy-protection-rule-coppa"
          target="_blank"
        >
          COPPA
        </StyledSpan>{" "}
        (Children’s Online Privacy Protection Act) to protect children’s online
        privacy.
      </Description>
      <FooterDescription>
        We do not share and will not share this information for any purpose.
      </FooterDescription>
    </MainContainer>
  );
};
export default BirthDetails;
