import React, { useRef } from "react";
import { FiArrowRight } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import bookImg from "../../images/bookimage.svg";
import theme from "../../styles/colors";
import { Body, Caption1, Headline, Title1 } from "../../styles/typography";
import SubCard from "./components/SubCard";
import SubAction from "../../components/SubAction";
import { TbHelp } from "react-icons/tb";
import configData from "../../config/cognitoConfig";

const BookEditorContainer = styled.div`
  position: relative;
  min-height: 100vh;
  /* 
   background-image: url('https://i.ibb.co/4VqFggW/banner.png');
   */
  background-image: url(${bookImg});
  background-size: cover;
  background-position: center;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-start;
  align-items: center;
`;

const ImageSection = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
const Image = styled.div`
  height: 40px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  width: 267px;
`;

const MainContainer = styled.div`
  background-color: ${theme.buttonPrimaryText};
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  width: 800px;
  left: 50%;
  top: 50%;
  /* transform: translate(-50%, -50%); */
  transition: 0.7s;
  @media only screen and (max-width: 999px) {
    width: 552px;
  }
  @media only screen and (max-width: 599px) {
    left: calc(50% - 24px);
    margin: auto 24px;
    width: calc(100vw - 24px);
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
const BookCard = styled.div`
  margin: 24px 24px 0px 24px;
  padding-bottom: 24px;
  height: 100%;
  transition: 0.1s;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
const CardHeader = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: ${(props) => props.margin};
  flex-direction: column;
  ${Body} {
    color: rgb(${(props) => props.theme.colors.neutral400});
  }
`;

const CardTitle = styled(Title1)`
  color: rgb(${(props) => props.theme.colors.neutral600});
  text-align: center;
`;

const CardBody = styled.div`
  width: 100%;
`;
const MainSubCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 12px;
  row-gap: 12px;
  width: 100%;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

const CardFooter = styled.div`
  position: ${(props) => props.position};
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const CardFooterText = styled(Caption1)`
  text-align: center;
  color: rgb(${(props) => props.theme.colors.neutral200});
`;

const CardButton = styled(Button)`
  bottom: ${(props) => props.bottom};
  position: ${(props) => props.position};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
`;

const Testimonial = styled.div`
  transition: 0.7s;
  display: grid;
  grid-template-columns: 0.1fr 3fr;
  padding: 24px;
  gap: 12px;
  align-items: center;
  background-color: rgba(${(props) => props.theme.colors.white}, 0.5);
  border-radius: 16px;
  width: 100%;
  width: 800px;
  @media only screen and (max-width: 999px) {
    width: 552px;
  }
  @media only screen and (max-width: 599px) {
    margin: auto 24px;
    width: calc(100vw - 24px);
    box-sizing: border-box;
  }
`;

const TestimonialImage = styled.div`
  width: 100px;
  height: 140px;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: contain;
`;

const navigateIfTokenExists = (params, navigate) => {
  if (params.get("token")) {
    try {
      const token = atob(params.get("token"));
      const tokenObj = JSON.parse(token);
      for (const [key, value] of Object.entries(tokenObj)) {
        try {
          // Try parsing the value. If it fails, use it as a string.
          const parsedValue = JSON.parse(value);
          localStorage.setItem(key, JSON.stringify(parsedValue));
        } catch {
          localStorage.setItem(key, value);
        }
      }
      setTimeout(() => {
        navigate("/app/bookedit");
      }, 0);
    } catch (error) {
      console.log("error", error);
    }
  }
};

const BookCreation = () => {
  const navigate = useNavigate();
  const bookRef = useRef(null);
  const MainContainerRef = useRef(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  navigateIfTokenExists(params, navigate);
  const bookCardData = [
    {
      title: 1,
      heading: "",
      description: "Select memories from your account",
    },
    {
      title: 2,
      heading: "",
      description: "Review and approve book draft and price",
    },
    {
      title: 3,
      heading: "",
      description: "Place your order (when you’re ready)",
    },
  ];

  const openLogin = () => {
    navigate("/app/bookedit");
  };

  return (
    <>
      <BookEditorContainer>
        <ImageSection>
          <Image
            src={
              "https://mystoriesmatter-prod-public-media.s3.amazonaws.com/assets/book-creator.png"
            }
          />
        </ImageSection>
        <MainContainer ref={MainContainerRef}>
          <Container>
            <BookCard ref={bookRef}>
              <CardHeader>
                <CardTitle>Print your Memory Book</CardTitle>
              </CardHeader>
              <CardButton variant="primary" onClick={openLogin}>
                Go to Book Creator <FiArrowRight />
              </CardButton>
              <CardBody>
                <MainSubCard>
                  {bookCardData.map((card, ind) => {
                    return (
                      <SubCard
                        key={Math.random() + ind}
                        title={card.title}
                        heading={card.heading}
                        description={card.description}
                      />
                    );
                  })}
                </MainSubCard>
              </CardBody>
              <div
                style={{
                  display: "flex",
                  gap: "12px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <SubAction
                  iconRight={<TbHelp />}
                  to="https://www.mystoriesmatter.com/support?utm_source=book-app"
                >
                  I need help
                </SubAction>
                <SubAction to="https://www.mystoriesmatter.com/gift-memory-book?utm_source=book-app">
                  Pre-buy a book for someone else's memories
                </SubAction>
              </div>
            </BookCard>
          </Container>
        </MainContainer>
        <Testimonial>
          <TestimonialImage image="https://mystoriesmatter-prod-public-media.s3.amazonaws.com/assets/debbie-book.png" />
          <div
            style={{
              fontFamily: "Inter",
              fontSize: "15px",
              fontStyle: "italic",
              fontWeight: 400,
              lineHeight: 1.25,
              textAlign: "center",
            }}
          >
            “My Stories Matter’s professional guidance and unwavering
            encouragement fueled my confidence to complete my book.”
            <br /> - Debbie
          </div>
        </Testimonial>
      </BookEditorContainer>
    </>
  );
};

export default BookCreation;
