import React, { useEffect } from "react";
import { getCurrentUser } from "@aws-amplify/auth";
import { useSessionStorage } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { redirectToLogin } from "../util/functions";
const ProtectedRoute = ({ element }) => {
  const navigate = useNavigate();
  const [isCognitoAuthed, setIsCognitoAuthed] = useSessionStorage(
    "isAuthed",
    null,
  );

  useEffect(() => {
    const isAuthed = async () => {
      try {
        await getCurrentUser();
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    };
    isAuthed().then((res) => {
      setIsCognitoAuthed(res);
      if (!res) {
        redirectToLogin({ from: new URL(window.location.href), navigate });
      }
    });
  }, [setIsCognitoAuthed, navigate]);

  if (isCognitoAuthed) {
    return <>{element}</>;
  }

  return <></>;
};

export default ProtectedRoute;
