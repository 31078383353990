import * as React from "react";
import styled from "styled-components";
import { Caption1, Caption2 } from "../styles/typography";
import { TbEye, TbEyeOff } from "react-icons/tb";

const InputContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 4px;
`;
const InputLabel = styled(Caption1)`
  color: rgb(${(props) => props.theme.colors.neutral200});
  margin-left: 8px;
  text-transform: uppercase;
`;
const StyledInput = styled.input`
  padding: 16px 24px 16px 24px;
  border: 2px solid
    rgb(
      ${(props) =>
        props.errorCondition
          ? props.theme.colors.error400
          : props.theme.colors.neutral100}
    );
  border-radius: 8px;
  font-family: "Inter";
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  color: rgb(${(props) => props.theme.colors.neutral600});
  box-sizing: border-box;

  :focus {
    border: 2px solid
      rgb(
        ${(props) =>
          props.errorCondition
            ? props.theme.colors.error400
            : props.theme.colors.neutral600}
      );
    box-shadow:
      0px 2px 2px rgba(46, 49, 62, 0.05),
      0px 4px 4px rgba(46, 49, 62, 0.05),
      0px 8px 8px rgba(46, 49, 62, 0.05),
      0px -4px 4px rgba(46, 49, 62, 0.05),
      -4px 0px 4px rgba(46, 49, 62, 0.05),
      4px 0px 4px rgba(46, 49, 62, 0.05);
    outline: none;
  }

  ::placeholder {
    color: rgb(${(props) => props.theme.colors.neutral200});
  }
`;

const ErrorMessage = styled(Caption2)`
  color: rgb(${(props) => props.theme.colors.error400});
  margin: 2px 4px;
`;
const InputWrapper = styled.div`
  position: relative;
`;
const EyeIcon = styled.span`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
  }
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
`;
const Input = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <InputContainer>
      <InputLabel>{props.label}</InputLabel>
      <InputWrapper>
        <StyledInput
          placeholder={props.placeholder}
          onChange={props.onValueChange}
          type={!showPassword ? props.type : "text"}
          value={props.value}
          name={props.name}
          onBlur={props.onBlur}
          errorCondition={props.errorCondition}
          min={props.min}
          max={props.max}
          data-clarity-mask="True"
          className="ph-no-capture"
          step={props.step}
          disabled={props.disabled}
          id={props.id}
          autoComplete={props.autocomplete}
        ></StyledInput>
        {props?.type === "password" && (
          <EyeIcon
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? <TbEyeOff /> : <TbEye />}
          </EyeIcon>
        )}
      </InputWrapper>
      {props.errorCondition ? (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      ) : null}
    </InputContainer>
  );
};

export default Input;
