import { fetchApi } from "../fetchInstance";
import {
  getBookListUrl,
  getCreateBookUrl,
  getMemoriesOfABookUrl,
  getMemoriesUrl,
  getUpdateBookUrl,
} from "./url";
/**
 * @typedef {{pageIndex:number,pageSize:number,orderBy:'createdDate'|'title'|'updatedDate'}} FetchMemoryDto
 */

/**
 * @typedef {{title:string,memoryID:string[]}} CreateBookDto
 */

const bookApi = {
  getBookList: () => {
    return fetchApi.get(getBookListUrl);
  },
  /**
   * @param {FetchMemoryDto} obj
   */
  getMemories: (obj) => {
    return fetchApi.get(getMemoriesUrl(obj));
  },

  /**
   *
   * @param {CreateBookDto} obj
   */
  createABook: (obj) => {
    return fetchApi.post(getCreateBookUrl, obj);
  },

  /**
   * @param {string} bookID
   */
  getMemoriesOfABook: (bookID) => {
    return fetchApi.get(getMemoriesOfABookUrl(bookID));
  },

  /**
   * @param {CreateBookDto} obj
   */
  updateBookMemories: (bookID, obj) => {
    return fetchApi.patch(getUpdateBookUrl(bookID), obj);
  },
};

export default bookApi;
