import { signInWithRedirect, signIn, signOut } from "@aws-amplify/auth";

function federatedSignIn(provider, customState) {
  return signInWithRedirect({ provider, customState });
}

function signInWithEmailAndPassword(email, password) {
  return signIn({ username: email, password });
}

function federatedSignOut() {
  return signOut();
}
export { federatedSignIn, signInWithEmailAndPassword, federatedSignOut };
