import * as React from "react";
import { ThemeProvider } from "styled-components";
import theme from "./styles/colors";
import routes from "./navigator/routes";
import { useRoutes, useLocation } from "react-router-dom";
import AppLayout from "./components/AppLayout";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";
import CognitoProvider from "./components/CognitoProvider";
import { PostHogProvider } from "posthog-js/react";
import { isProd } from "./util/functions";
import { PosthogListener } from "./components/PosthogProvider";
import { posthog } from "posthog-js";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const getPostHogAPIHostURL = () => {
  const isDevelopment = process.env.NODE_ENV === "development";
  if (isDevelopment) {
    return "http://localhost:8000/ingest";
  }
  if (isProd) {
    return "https://book.mystoriesmatter.com/ingest";
  }
  return `https://${process.env.GATSBY_VERCEL_URL}/ingest`;
};

const App = () => {
  const options = {
    api_host: getPostHogAPIHostURL(),
    capture_pageview: false,
    ui_host: "https://us.posthog.com",
  };

  const isLoggedIn = Cookies.get("idToken") ? true : false;
  const routing = useRoutes(routes(isLoggedIn));
  let location = useLocation();
  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
    posthog.capture("$pageview");
  }, [location]);
  return (
    <React.StrictMode>
      <PostHogProvider
        options={options}
        apiKey="phc_FRKr89GXX35NDOXRiXRSZY6dCi9XBKqsIWbCj3dlFQI"
      >
        <CognitoProvider>
          <PosthogListener />
          <ThemeProvider theme={theme}>
            <AppLayout />
            {routing}
          </ThemeProvider>
        </CognitoProvider>
      </PostHogProvider>
    </React.StrictMode>
  );
};

export default App;
