import { fetchApi } from "../fetchInstance";
import {
  getRegisterUrl,
  getResendActivationEmailUrl,
  getUpdateLoginCountUrl,
  getUpdateUserConfigUrl,
  getUserSignupMethodUrl,
} from "./url";

/**
 * @typedef {{frequency: number,startDate: string,starterEmailSent: boolean}} WritingRemindersProps
 */

/**
 * @typedef {{email:string,password:string,firstName:string,lastName:string,birthYear:number,authRedirectURL:string}} RegisterDTO
 */

/**
 * @typedef {{email:string}} ResendActivationEmailDto
 */

const userApi = {
  updateLoginCount: async () => {
    return fetchApi.patch(getUpdateLoginCountUrl);
  },
  getUserSignUpMethod: (emailID) => {
    return fetchApi.get(getUserSignupMethodUrl(emailID));
  },

  /**
   * @description update user config
   * @param {{promptRecommendation: boolean,
   * welcomePopup?: boolean,
   * introductoryData?: boolean,
   * onboardingSeries?: boolean,
   * emailNotification?: boolean,
   * writingReminder?: WritingRemindersProps | Record<string, never>,
   * firstTimeMemoryDelete?: boolean,
   * showCuesModal?: boolean,
   * showTimelinePromptsOnboarding?: boolean,
   * addedInCollaborationAvailableList?: boolean,
   * onboarding?: OnboardingEnum,
   * videoPopupPerView?: string,
   * maintanancePopViewed?: boolean,
   * memoryBookUser?: boolean,
   * writingReminderPopupViewed?: boolean}} config
   */
  updateConfig: (config) => {
    return fetchApi.patch(getUpdateUserConfigUrl, config);
  },

  /**
   *
   * @param {RegisterDTO} registerDto
   */
  register: (registerDto) => {
    return fetchApi.post(getRegisterUrl, registerDto);
  },

  /**
   * @param {ResendActivationEmailDto} resendActivationEmailDto
   */
  resendActivationEmail: (resendActivationEmailDto) => {
    return fetchApi.post(getResendActivationEmailUrl, resendActivationEmailDto);
  },
};
export default userApi;
