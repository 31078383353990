import { isProd, isQA } from "../../util/functions";
const API_VERSION = "/api/v1";
const MICROSERVICE_NAME = "/book";
/**
 * @typedef {{pageIndex:number,pageSize:number,orderBy:'createdDate'|'title'|'updatedDate'}} FetchMemoryDto
 */
const baseURL = () => {
  if (isProd) {
    return `https://memory.api.mystoriesmatter.com${API_VERSION}${MICROSERVICE_NAME}`;
  }
  if (isQA) {
    return `https://qa-memory.api.mystoriesmatter.com${API_VERSION}${MICROSERVICE_NAME}`;
  }
  return `https://dev-memory.api.mystoriesmatter.com${API_VERSION}${MICROSERVICE_NAME}`;
};

export const getBookListUrl = `${baseURL()}`;

/**
 *
 * @param {FetchMemoryDto} obj
 * @returns
 */
export const getMemoriesUrl = (obj) =>
  `${baseURL()}/memories?pageIndex=${obj.pageIndex}&pageSize=${obj.pageSize}&orderBy=${obj.orderBy}`;

export const getCreateBookUrl = `${baseURL()}`;

export const getMemoriesOfABookUrl = (bookID) => `${baseURL()}/${bookID}`;

export const getUpdateBookUrl = (bookID) => `${baseURL()}/${bookID}`;
