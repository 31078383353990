import React from "react";

import { SpinnerCircular } from "spinners-react";
import theme from "../styles/colors";
import styled, { css } from "styled-components";
import { Subhead } from "../styles/typography";
import { Link } from "react-router-dom";

export const ButtonStyles = css`
  padding: 12px 16px;
  border-radius: ${(props) => (props.icon ? "50%" : "100px")};
  text-decoration: none;
  min-width: ${(props) => (props.icon ? "50px" : "112px")};
  height: ${(props) => (props.icon ? "50px" : "44px")};
  text-align: center;
  background: ${(props) =>
    props.variant === "primary"
      ? props.theme.buttonPrimaryBg
      : props.variant === "warning"
        ? `rgb(${props.theme.colors.error100})`
        : props.variant === "tertiary"
          ? "white"
          : props.theme.buttonSecondaryBg};
  color: rgb(
    ${(props) =>
      props.variant === "primary"
        ? props.theme.colors.white
        : props.variant === "warning"
          ? props.theme.colors.error400
          : props.theme.colors.neutral200}
  );
  box-sizing: border-box;
  display: inline-grid;
  grid-auto-flow: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid
    ${(props) =>
      props.variant === "primary"
        ? props.theme.buttonPrimaryBorder
        : props.variant === "warning"
          ? `rgb(${props.theme.colors.error200})`
          : props.theme.buttonSecondaryBorder};
  :hover:not(:disabled) {
    box-shadow: ${(props) =>
      props.variant === "primary" ? `${props.theme.shadows.shadow600()}` : ""};
    border: 2px solid
      rgb(
        ${(props) =>
          props.variant === "primary"
            ? props.theme.colors.neutral600
            : props.variant === "warning"
              ? props.theme.colors.error400
              : props.theme.colors.neutral200}
      );

    color: rgb(
      ${(props) => props.variant === "warning" && props.theme.colors.error600}
    );
  }
  :disabled {
    /* background: rgb(${(props) => props.theme.colors.neutral100});
    border-color: rgb(${(props) => props.theme.colors.neutral100});
    color: rgb(${(props) => props.theme.colors.neutral200}); */
    cursor: not-allowed;
    opacity: 0.5;
    :hover {
      box-shadow: none;
    }
  }
`;

export const ButtonItemsWrapper = styled(Subhead)`
  display: inline-grid;
  grid-auto-flow: column;
  align-content: center;
  align-items: center;
  gap: 12px;
  justify-content: center;
  justify-items: center;
`;

export const StyledLink = styled(Link)`
  ${ButtonStyles}
`;

export const StyledA = styled.a`
  ${ButtonStyles}
`;

export const StyledButton = styled.button`
  ${ButtonStyles}
`;

const Loader = ({ type }) => {
  let loader = (
    <SpinnerCircular
      size={24}
      thickness={200}
      speed={100}
      color={`rgba(${theme.colors.white}, 1)`}
      secondaryColor={`rgba( ${theme.colors.neutral600}, 1)`}
    />
  );
  if (type === "primary")
    loader = (
      <SpinnerCircular
        size={24}
        thickness={200}
        speed={100}
        color={`rgba(${theme.colors.white}, 1)`}
        secondaryColor={`rgba( ${theme.colors.neutral600}, 1)`}
      />
    );
  if (type === "secondary")
    loader = (
      <SpinnerCircular
        size={24}
        thickness={200}
        speed={100}
        color={`rgba(${theme.colors.white}, 1)`}
        secondaryColor={`rgba( ${theme.colors.neutral600}, 1)`}
      />
    );
  return loader;
};

const Button = ({
  children,
  to,
  state,
  variant = "secondary",
  className,
  disabled,
  onButtonClick,
  icon,
  loading = false,
  ...props
}) => {
  if (!to) {
    return (
      <StyledButton
        className={className}
        variant={variant}
        disabled={disabled}
        onClick={onButtonClick}
        icon={icon}
        {...props}
      >
        <ButtonItemsWrapper>
          {loading ? <Loader type={variant} /> : children}
        </ButtonItemsWrapper>
      </StyledButton>
    );
  } else {
    if (to.charAt(0) === "/" || to.charAt(0) === "#") {
      return (
        <StyledLink
          className={className}
          to={to}
          variant={variant}
          state={state}
          target={props.target}
          {...props}
        >
          <ButtonItemsWrapper key={`${loading}`}>
            {loading ? <Loader type={variant} /> : children}
          </ButtonItemsWrapper>
        </StyledLink>
      );
    } else if (
      to.substring(0, 4) === "http" ||
      to.substring(0, 6) === "mailto"
    ) {
      return (
        <StyledA
          className={className}
          href={to}
          target={"_blank"}
          variant={variant}
          {...props}
        >
          <ButtonItemsWrapper key={`${loading}`}>
            {loading ? <Loader type={variant} /> : children}
          </ButtonItemsWrapper>
        </StyledA>
      );
    } else {
      return <></>;
    }
  }
};
export default Button;
