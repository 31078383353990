import BookCreation from "../features/book/BookCreation";
import BookEditor from "../features/book/BookEdit";
import RedirectUrl from "../components/RedirectUrl";
import BookSubmission from "../features/book/BookSubmission";

import { Navigate } from "react-router-dom";
import Login from "../features/user/Login";
import ProtectedRoute from "../components/ProtectedRoute";
import AuthCallBack from "../features/user/Callback";
import Logout from "../features/logout";
import ThankYouComponent from "../features/book/components/ThankYouComponent";

const routes = (isLoggedIn) => [
  {
    path: "/app",
    element: <ProtectedRoute element={<BookCreation />} />,
  },
  {
    path: "/app/bookcreation",
    element: <ProtectedRoute element={<BookCreation />} />,
  },
  {
    path: "/app/bookedit",
    element: <ProtectedRoute element={<BookEditor />} />,
  },
  {
    path: "/app/booksubmission",
    element: <ProtectedRoute element={<BookSubmission />} />,
  },
  {
    path: "/login-redirect",
    element: isLoggedIn ? (
      <Navigate to="/app/bookedit" />
    ) : (
      <RedirectUrl url={process.env.REACT_APP_lOGIN_URL} />
    ),
  },
  {
    path: "/mystories-matter",
    element: <RedirectUrl url="https://mystoriesmatter.com" />,
  },
  {
    path: "/",
    element: <BookCreation />,
  },
  {
    path: "/user",
    element: <Login />,
  },
  {
    path: "*",
    element: <BookCreation />,
  },
  {
    path: "/callback",
    element: <AuthCallBack />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/app/thank-you",
    element: <ThankYouComponent />,
  },
];

export default routes;
