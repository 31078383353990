import styled from "styled-components";

const CardBody = styled.div`
  padding: ${(props) => props.padding};
  overflow: auto;
  width: auto;
  height: ${(props) => (props.length < 5 ? "auto" : "500px")};
  background: #f3f5f7;
  border: 2px solid #e2e4e9;
  border-radius: 16px;
  @media only screen and (max-width: 599px) {
    height: ${(props) => (props.length < 5 ? "auto" : "525px")};
    border-radius: 0px;
    width: 100%;
  }
  .test_scroll::-webkit-scrollbar {
    width: 8px;
    box-sizing: border-box;
  }
  .test_scroll::-webkit-scrollbar-track {
    max-height: 50%;
    height: 50%;
  }
  .test_scroll::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 16px;
    border-left: 1px solid #e2e4e9;
  }

  .test_scroll::-webkit-scrollbar-thumb {
    background: #c1c3ca;
    border-radius: 10px;
    height: 3px !important;
  }
`;

export default CardBody;
