import { isProd, isQA } from "../../util/functions";
const API_VERSION = "/api/v1";
const MICROSERVICE_NAME = "/users";

const baseURL = () => {
  if (isProd) {
    return `https://user.api.mystoriesmatter.com${API_VERSION}${MICROSERVICE_NAME}`;
  }
  if (isQA) {
    return `https://qa-user.api.mystoriesmatter.com${API_VERSION}${MICROSERVICE_NAME}`;
  }
  return `https://dev-user.api.mystoriesmatter.com${API_VERSION}${MICROSERVICE_NAME}`;
};

export const getFederatedSignInUrl = `${baseURL()}/authentication/add-cognito-user`;

export const getRegisterUrl = `${baseURL()}/authentication/register`;

export const getUpdateUserConfigUrl = `${baseURL()}/config`;

export const getResendActivationEmailUrl = `${baseURL()}/authentication/resend-email`;

export const getUserSignupMethodUrl = (emailID) =>
  `${baseURL()}/authentication/check-provider?emailID=${emailID}`;

export const getUpdateLoginCountUrl = `${baseURL()}/updatelogincount/`;
