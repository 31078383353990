import userApi from "../../app-api/users";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  amplifyError: null,
  loginProvider: { provider: null, isLoading: false, isMigrated: null },
  error: null,
  register: {
    isLoading: false,
    response: null,
  },
};

const resendActivationEmail = createAsyncThunk(
  "user/resend_email",
  async (obj, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await userApi.resendActivationEmail(obj);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getUserSignUpMethod = createAsyncThunk(
  "user/get_user_signup_method",
  async (email, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = userApi.getUserSignUpMethod(email);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const register = createAsyncThunk(
  "user/register",
  async (obj, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await userApi.register(obj);
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);
export const UserSlice = createSlice({
  initialState,
  name: "users",
  reducers: {
    reset: () => initialState,
    setAmplifyError: (state, action) => {
      state.amplifyError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserSignUpMethod.pending, (state, action) => {
        if (!state.loginProvider.isLoading) {
          state.loginProvider.isLoading = true;
        }
      })
      .addCase(getUserSignUpMethod.fulfilled, (state, action) => {
        if (state.loginProvider.isLoading) {
          state.loginProvider.provider =
            action.payload.data.identityProviderType;
          state.loginProvider.isMigrated = action.payload.data.isMigrated;
          state.loginProvider.isLoading = false;
        }
      })
      .addCase(getUserSignUpMethod.rejected, (state, action) => {
        if (state.loginProvider.isLoading) {
          state.loginProvider.isLoading = false;
          state.error = action.error;
        }
      });
  },
});

export default UserSlice.reducer;

export const userActions = {
  resendActivationEmail,
  getUserSignUpMethod,
  register,
};

export const { setAmplifyError } = UserSlice.actions;
export const selectAmplifyError = (state) => state.users.amplifyError;
export const selectProvider = (state) => state.users.loginProvider.provider;
export const selectLoginMethodLoading = (state) =>
  state.users.loginProvider.isLoading;
export const selectIsMigrated = (state) => state.users.loginProvider.isMigrated;
export const selectIsRegisterLoading = (state) =>
  state.users.register.isLoading;
export const selectRegisterResponse = (state) => state.users.register.response;
