import { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { SpinnerCircular } from "spinners-react";
import { useDispatch } from "react-redux";
import isBrowser from "../util/DetectBrowser";
import { useNavigate } from "react-router-dom";
import { reset } from "../app/reducers/BookMemorySlice";
import { federatedSignIn, federatedSignOut } from "../auth/cognitoAuth";

const Wrapper = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = () => {
  const theme = useTheme();
  return (
    <SpinnerCircular
      size={48}
      thickness={200}
      aria-label="Loading Spinner"
      speed={100}
      color={`rgba(${theme.colors.neutral200}, 1)`}
      secondaryColor={`rgba(${theme.colors.neutral100}, 1)`}
      style={{
        justifySelf: "center",
        position: "absolute",
        left: "45%",
        top: "45%",
      }}
    />
  );
};
const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(reset());
    if (isBrowser) {
      federatedSignOut()
        .then(() => {
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dispatch, navigate]);
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
};

export default Logout;
