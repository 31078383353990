/* eslint-disable import/no-named-as-default */
import { configureStore } from "@reduxjs/toolkit";
import HeadSlice from "./reducers/HeadSlice";
import BookMemorySlice from "./reducers/BookMemorySlice";
import UserSlice from "./reducers/UserSlice";
export const store = configureStore({
  reducer: {
    head: HeadSlice,
    bookMemory: BookMemorySlice,
    users: UserSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
