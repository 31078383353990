import * as React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { store } from "./app/Store";
// Import Lora font
import "@fontsource/lora/500.css";
import "@fontsource/lora/600.css";
// Import Inter Font
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import { createRoot } from "react-dom/client";
import "./styles/global.css";

const container = document.getElementById("book-app");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
);

// export default LandingPage;
