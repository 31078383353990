import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { reset } from "../../../app/reducers/BookMemorySlice";
import { Body } from "../../../styles/typography";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  TbBook,
  TbChevronDown,
  TbListNumbers,
  TbLogout,
  TbMenu2,
  TbMessageQuestion,
  TbX,
} from "react-icons/tb";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery } from "usehooks-ts";
import React, { forwardRef, useState } from "react";
const ImageSection = styled.div`
  position: fixed;
  width: 100%;
  background: white;
  border-bottom: 2px solid rgb(${(props) => props.theme.colors.neutral600});
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Image = styled.img`
  object-fit: contain;
  max-height: 30px;
  cursor: pointer;
`;

const RightSection = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

const Divider = styled.div`
  background-color: rgb(${(props) => props.theme.colors.neutral100});
  width: 2px;
  height: 40px;
`;

const Logout = styled(Body)`
  cursor: pointer;
`;

const StyledTrigger = styled(DropdownMenu.Trigger)`
  display: flex;
  gap: 4px;
  align-items: center;
  align-self: stretch;
  color: rgb(${(props) => props.theme.colors.neutral400});
  svg {
    min-height: 20px;
    min-width: 20px;
  }
`;

const DropdownContent = styled(motion.div)`
  padding: 8px;
  background-color: rgb(${(props) => props.theme.colors.white});
  border: 2px solid rgb(${(props) => props.theme.colors.neutral150});
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  box-shadow:
    0px 8px 16px 0px rgba(11, 12, 15, 0.07),
    0px 4px 8px 0px rgba(11, 12, 15, 0.07),
    0px 0px 4px 0px rgba(11, 12, 15, 0.07);
`;

const TriggerContainer = styled.div`
  background-color: transparent;
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  padding: 12px 8px;
  @media (hover: hover) {
    :hover {
      background-color: rgba(226, 228, 233, 0.5);
    }
  }
  &[data-state="open"] {
    background-color: rgba(226, 228, 233, 1);
    svg {
      rotate: 180deg;
    }
  }
  rotate: 0deg;
  transition-property: background-color rotate;
  transition-duration: 200ms;
  svg {
    transition-property: rotate;
    transition-duration: 200ms;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 8px;
  border-radius: 8px;
  :link {
    text-decoration: none;
  }
  ${Body} {
    color: rgb(${(props) => props.theme.colors.neutral200});
  }
  svg {
    min-height: 20px;
    min-width: 20px;
    color: rgb(${(props) => props.theme.colors.neutral200});
  }
  @media (hover: hover) {
    :hover {
      background-color: rgba(226, 228, 233, 0.5);
    }
  }
`;

const Hamburger = styled.div`
  width: 40px;
  height: 40px;
  background-color: rgb(${(props) => props.theme.colors.offWhite});
  border: 2px solid rgb(${(props) => props.theme.colors.neutral150});
  border-radius: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  svg {
    min-width: 20px;
    min-height: 20px;
  }
`;

const DesktopTrigger = forwardRef((props, ref) => {
  return (
    <TriggerContainer ref={ref} {...props}>
      <Body>Resources</Body>
      <TbChevronDown />
    </TriggerContainer>
  );
});
const MobileTrigger = forwardRef((props, ref) => {
  return (
    <Hamburger ref={ref} {...props}>
      <AnimatePresence initial={false}>
        {props.open && (
          <TbX
            as={motion.svg}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
        {!props.open && (
          <TbMenu2
            as={motion.svg}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
    </Hamburger>
  );
});

const DropDownItems = () => {
  const isMobile = useMediaQuery("(max-width: 800px)");
  return (
    <React.Fragment>
      <StyledLink
        href={
          "https://www.mystoriesmatter.com/memory-book?utm_source=book-app&no-redirect=true"
        }
        as="a"
        target="_blank"
        rel="noreferrer noopener"
      >
        <TbBook />
        <Body>View a book sample</Body>
      </StyledLink>
      <StyledLink
        href={
          "https://www.mystoriesmatter.com/memory-book?utm_source=book-app&no-redirect=true"
        }
        as="a"
        target="_blank"
        rel="noreferrer noopener"
      >
        <TbListNumbers />
        <Body>Read our process</Body>
      </StyledLink>
      <StyledLink
        href={
          "https://www.mystoriesmatter.com/concierge-life-stories?utm_source=book-app&no-redirect=true"
        }
        as="a"
        target="_blank"
        rel="noreferrer noopener"
      >
        <TbMessageQuestion />
        <Body>Concierge interviews</Body>
      </StyledLink>
      {isMobile && (
        <StyledLink to={"/logout"}>
          <TbLogout />
          <Body>Log out</Body>
        </StyledLink>
      )}
    </React.Fragment>
  );
};
const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 800px)");

  const [dropDownOpen, setDropDownOpen] = useState(false);
  return (
    <ImageSection>
      <Image
        onClick={() => {
          dispatch(reset());
          navigate("/");
        }}
        src={
          "https://mystoriesmatter-prod-public-media.s3.amazonaws.com/assets/book-creator.png"
        }
      />
      <RightSection>
        <DropdownMenu.Root
          open={dropDownOpen}
          onOpenChange={setDropDownOpen}
          modal={false}
        >
          <StyledTrigger asChild>
            {isMobile ? (
              <MobileTrigger open={dropDownOpen} />
            ) : (
              <DesktopTrigger />
            )}
          </StyledTrigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content align="end" sideOffset={8}>
              <DropdownContent
                animate={{ scale: 1, opacity: 1 }}
                initial={{ scale: 0.95, opacity: 0 }}
                style={{ transformOrigin: "top left" }}
                transition={{
                  duration: 0.1,
                }}
              >
                <DropDownItems />
              </DropdownContent>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
        <AnimatePresence initial={false}>
          {!isMobile && (
            <motion.div
              style={{
                display: "flex",
                gap: "32px",
                alignItems: "center",
              }}
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
            >
              <Divider />
              <Logout as={Link} to={"/logout"}>
                Log out
              </Logout>
            </motion.div>
          )}
        </AnimatePresence>
      </RightSection>
    </ImageSection>
  );
};

export default Navbar;
