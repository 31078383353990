import { isProd } from "../util/functions";

const configData = {
  clientId: isProd
    ? "69jvprlnrn510anfleh8o1odjl"
    : "5o9veq7lnv99bq652ral37jla0",

  userPoolUri: isProd
    ? "auth.mystoriesmatter.com"
    : "dev-mystoriesmatter.auth.us-east-1.amazoncognito.com",
  userPool: isProd ? "us-east-1_fZHFTwUBV" : "us-east-1_j3k3Io9Ta",
  region: "us-east-1",
  callbackUri: isProd
    ? "https://book.mystoriesmatter.com/callback"
    : "http://localhost:8000/callback",
  signoutUri: isProd
    ? "https://book.mystoriesmatter.com"
    : "http://localhost:8000/",
  tokenScopes: ["openid", "email", "profile"],
};
export default configData;
