import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { UpdateBookMemories } from "../../app-api/api";
import bookApi from "../../app-api/books";
import { generalResponse } from "../../util/Format";
import { getDomain } from "../../util/functions";

/**
 * @typedef {{pageIndex:number,pageSize:number,orderBy:'createdDate'|'title'|'updatedDate'}} FetchMemoryDto
 */
/**
 * @typedef {{title:string,memoryID:string[]}} CreateBookDto
 */

/**
 * @typedef {{title:string,memoryID:string[],bookID:string}} UpdateBookDto
 */
export const fetchBookMemories = createAsyncThunk(
  "memory/draftsAndPublished",
  /**
   *
   * @param {FetchMemoryDto} obj
   * @param {*} param1
   * @returns
   */
  async (obj, { rejectWithValue }) => {
    try {
      const result = bookApi.getMemories(obj);
      return result;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const fetchCurrentUserBookMemories = createAsyncThunk(
  "memory/recentPublishedApi",
  /**
   *
   * @param {FetchMemoryDto} obj
   * @returns
   */
  async (obj, { rejectWithValue }) => {
    try {
      const result = bookApi.getMemories(obj);
      return result;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const addBookMemories = createAsyncThunk(
  "memory/AddBookMemories",
  /**
   * @param {CreateBookDto} obj
   * @param {*} param1
   * @returns
   */
  async (obj, { rejectWithValue }) => {
    try {
      const result = await bookApi.createABook(obj);
      return result;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const getBookMemories = createAsyncThunk(
  "memory/getBookMemories",
  /**
   * @param {string} bookID
   */
  async (bookID, { rejectWithValue }) => {
    try {
      const result = await bookApi.getMemoriesOfABook(bookID);
      return result;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const updateBookMemories = createAsyncThunk(
  "memory/updateBookMemories",
  /**
   * @param {UpdateBookDto} obj
   */
  async (obj, { rejectWithValue }) => {
    try {
      const { bookID, ...rest } = obj;
      const response = await bookApi.updateBookMemories(bookID, rest);
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const getBookList = createAsyncThunk(
  "memory/GetBookList",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await bookApi.getBookList();
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

const initialState = {
  bookMemories: [],
  books: [],
  selectedMemories: [],
  bookUpdateStatus: null,
  bookMemoriesStatus: "",
  bookMemoriesAdded: null,
  page: 0,
  hasMoreData: true,
  hasBooks: null,
  hasMemories: null,
  totalMemories: 0,
  orderBy: "createdDate",
  sortBy: "DESC",
  hasFilterChanged: false,
  selectedBook: null,
};
const domain = getDomain();
export const BookMemorySlice = createSlice({
  name: "bookMemory",
  initialState,
  reducers: {
    reset: () => initialState,
    sortBookMemoriesByAlphabetical: (state) => {
      state.bookMemories.sort((a, b) => (a.title > b.title ? 1 : -1));
    },
    sortBookMemoriesByChronological: (state) => {
      state.bookMemories.sort((a, b) => a.memory_date - b.memory_date);
    },
    pageCounter: (state) => {
      state.page += 1;
    },
    setBookMemoriesState: (state, action) => {
      state.orderBy = action?.payload?.orderBy;
      state.sortBy = action?.payload?.sortBy;
      state.hasFilterChanged = true;
      state.bookMemories.sort((a, b) => {
        if (action?.payload?.orderBy === "title") {
          //alphabetical
          return a.title > b.title ? 1 : -1;
        }
        if (action?.payload?.orderBy === "updatedDate") {
          // most recent
          return a.date.updated > b.date.updated ? -1 : 1;
        }
        // chronological
        return a.date.created > b.date.created ? -1 : 1;
      });
    },
  },
  extraReducers: {
    [fetchBookMemories.pending]: (state) => {
      state.bookMemoriesStatus = "loading";
      state.hasFilterChanged = true;
    },
    [fetchBookMemories.fulfilled]: (state, action) => {
      state.bookMemoriesStatus = "idle";
      state.hasFilterChanged = false;
      if (
        action?.payload &&
        action?.payload?.code &&
        action?.payload?.code === 200
      ) {
        state.bookMemories.length = 0;
        const newMemories = [
          ...state?.bookMemories,
          ...(action?.payload?.data ? action?.payload?.data : []),
        ];
        state.bookMemories = newMemories;
        state.totalMemories = action?.payload?.page?.totalItems;
        state.hasMoreData =
          newMemories.length !== action?.payload?.page.totalItems;
      } else {
        Cookies.remove("uid", { path: "/", domain: domain });
        Cookies.remove("idToken", { path: "/", domain: domain });
      }
    },
    [fetchBookMemories.rejected]: (state, action) => {
      state.bookMemoriesStatus = "failed";
      Cookies.remove("uid", { path: "/", domain: domain });
      Cookies.remove("idToken", { path: "/", domain: domain });
    },
    [fetchCurrentUserBookMemories.pending]: (state) => {
      state.status = "loading";
    },
    [fetchCurrentUserBookMemories.fulfilled]: (state, action) => {
      state.status = "idle";
      if (
        action?.payload &&
        action?.payload?.code &&
        action?.payload?.code === 200
      ) {
        const newMemories = [
          ...state?.bookMemories,
          ...(action?.payload?.data ? action?.payload?.data : []),
        ];
        state.bookMemories = newMemories;

        state.hasMoreData =
          newMemories.length !== action?.payload?.page.totalItems;
      } else {
        Cookies.remove("uid", { path: "/", domain: domain });
        Cookies.remove("idToken", { path: "/", domain: domain });
      }
    },
    [fetchCurrentUserBookMemories.rejected]: (state, action) => {
      state.status = "failed";
      state.hasMoreData = false;
      localStorage.clear();
      window.location.reload();
      state.error = action.payload;
    },
    [addBookMemories.pending]: (state) => {
      state.hasBooks = null;
      state.bookMemoriesAdded = false;
    },
    [addBookMemories.fulfilled]: (state, action) => {
      if (
        action?.payload &&
        action?.payload?.code &&
        action?.payload?.code === 200
      ) {
        state.bookMemoriesAdded = true;
      } else {
        state.bookMemoriesAdded = false;
      }
    },
    [addBookMemories.rejected]: (state) => {
      state.hasBooks = null;
      state.bookMemoriesAdded = false;
    },
    [getBookList.pending]: (state) => {
      state.hasBooks = null;
      state.bookUpdateStatus = null;
    },
    [getBookList.fulfilled]: (state, action) => {
      if (
        action?.payload &&
        action?.payload?.code &&
        action?.payload?.code === 200
      ) {
        state.hasBooks = action?.payload?.data?.length > 0 ? true : false;
        state.books = action?.payload?.data;
        state.hasMemories = null;
      } else {
        Cookies.remove("uid", { domain: domain });
        Cookies.remove("idToken", { domain: domain });
      }
    },
    [getBookList.rejected]: (state) => {
      state.hasBooks = null;
      state.books = [];
    },
    [getBookMemories.pending]: (state) => {
      state.hasMemories = null;
    },
    [getBookMemories.fulfilled]: (state, action) => {
      if (
        action?.payload &&
        action?.payload?.code &&
        action?.payload?.code === 200
      ) {
        state.selectedMemories.length = 0;
        state.selectedMemories = [
          ...state?.selectedMemories,
          ...(action?.payload?.data ? action?.payload?.data?.memoryID : []),
        ];
        state.hasMemories = state?.selectedMemories?.length ? true : false;
        state.selectedBook = action?.payload?.data?.book;
      } else {
        Cookies.remove("uid", { path: "/", domain: domain });
        Cookies.remove("idToken", { path: "/", domain: domain });
      }
    },
    [getBookMemories.rejected]: (state) => {
      state.hasMemories = null;
    },
    [updateBookMemories.pending]: (state) => {
      state.bookUpdateStatus = false;
    },
    [updateBookMemories.fulfilled]: (state, action) => {
      if (
        action?.payload &&
        action?.payload?.code &&
        action?.payload?.code === 200
      ) {
        state.bookUpdateStatus = true;
      } else {
        Cookies.remove("uid", { path: "/", domain: domain });
        Cookies.remove("idToken", { path: "/", domain: domain });
      }
    },
    [updateBookMemories.rejected]: (state) => {
      state.bookUpdateStatus = false;
    },
  },
});

export const {
  sortBookMemoriesByAlphabetical,
  sortBookMemoriesByChronological,
  pageCounter,
  setBookMemoriesState,
  reset,
} = BookMemorySlice.actions;

export const selectBookMemories = (state) => {
  return state?.bookMemory?.bookMemories;
};
export const selectBookMemoriesStatus = (state) =>
  state?.bookMemory?.bookMemoriesStatus;
export const selectPage = (state) => state?.bookMemory?.page;
export const selectOrderBy = (state) => state?.bookMemory?.orderBy;
export const selectSortBy = (state) => state?.bookMemory?.sortBy;
export const selectHasMoreData = (state) => state?.bookMemory?.hasMoreData;
export const selectHasBooks = (state) => state?.bookMemory?.hasBooks;
export const selectTotalMemories = (state) => state?.bookMemory.totalMemories;
export const selectSelectedMemories = (state) =>
  state?.bookMemory.selectedMemories;
export const selectBookUpdateStatus = (state) =>
  state?.bookMemory?.bookUpdateStatus;
export const selectBookMemoriesAdded = (state) =>
  state?.bookMemory?.bookMemoriesAdded;
export const selectHasBookMemories = (state) => state?.bookMemory?.hasMemories;
export const selectBooks = (state) => state.bookMemory.books;
export const selectCurrentBook = (state) => state.bookMemory.selectedBook;
export default BookMemorySlice.reducer;
