import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import styled from "styled-components";
import {
  getBookList,
  selectBooks,
  selectHasBooks,
  setBookMemoriesState,
} from "../../app/reducers/BookMemorySlice";
import Button from "../../components/Button";
import theme from "../../styles/colors";
import {
  Caption1,
  Footnote,
  Headline,
  Subhead,
  Title1,
} from "../../styles/typography";
import { BookStatusToText } from "../../util/functions";
import LogoutButton from "../user/LogoutButton";
import BooKImage from "./components/Navbar";
import CardBody from "./components/CardBodyCss";
import SampleBook from "./components/SampleBook";

const BookSubmissionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  margin-top: 114px;
  @media only screen and (max-width: 600px) {
    box-sizing: border-box;
  }
  @media only screen and (min-width: 320px) and (max-width: 599px) {
    box-sizing: border-box;
  }
  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    overflow: hidden;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    align-self: center;
  }
  @media only screen and (min-width: 1000px) {
    width: 100%;
    max-width: 1000px;
    padding: 0 24px;
    align-self: center;
  }
`;

const BookCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const CardHeader = styled.div`
  margin: ${(props) => props.margin};
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 599px) {
    text-align: start;
    padding: 0px 24px;
  }
`;

const CardTitle = styled(Title1)`
  color: ${theme.buttonPrimaryBg};
  font-size: 30px;
  line-height: 125%;
`;

const CardDescription = styled(Headline)`
  color: ${`rgb(${theme.colors.neutral400})`};
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CardRow = styled.div`
  cursor: pointer;
`;

const CheckContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  //   gap: 4px;
  background: #ffffff;
  box-shadow:
    0px 1px 1px rgba(7, 53, 98, 0.05),
    0px 2px 2px rgba(7, 53, 98, 0.05);
  border-radius: 8px;
`;

const ContentTitle = styled(Subhead)`
  color: ${`rgb(${theme.colors.neutral600})`};
  word-break: break-word;
`;

const ContentDetails = styled(Caption1)`
  color: ${`rgb(${theme.colors.neutral400})`};
`;

const StatusText = styled(Footnote)`
  align-self: center;
`;

const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding-bottom: 24px;
  @media only screen and (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
  }
`;
const CardButton = styled(Button)`
  @media only screen and (min-width: 320px) and (max-width: 599px) {
    width: 90%;
  }
`;
const StyledBr = styled.br``;

const BookSubmission = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const books = useSelector(selectBooks);
  const hasBooks = useSelector(selectHasBooks);
  useEffect(() => {
    dispatch(getBookList());
    const order = {
      orderBy: "changed",
      sortBy: "DESC",
    };
    dispatch(setBookMemoriesState(order));
  }, [dispatch]);

  useEffect(() => {
    if (hasBooks === false) {
      navigate("/app/bookedit?bookId=0");
    }
  }, [hasBooks, navigate]);

  return (
    <BookSubmissionContainer>
      <BooKImage />
      <MainContainer>
        <BookCard>
          <CardHeader>
            <CardTitle>Your books</CardTitle>
            <CardDescription>
              Click to manage your books below. You’ll only be able to edit
              those that are “Submitted.”
            </CardDescription>
          </CardHeader>

          <CardBody
            padding="24px 24px 24px 24px"
            className="test_scroll"
            id="overflowMain"
          >
            {hasBooks === null ? (
              <>
                <SpinnerCircular
                  size={48}
                  thickness={200}
                  aria-label="Loading Spinner"
                  speed={100}
                  color={`rgba(${theme.colors.neutral200}, 1)`}
                  secondaryColor={`rgba(${theme.colors.neutral100}, 1)`}
                  style={{
                    justifySelf: "center",
                    position: "relative",
                    left: "45%",
                  }}
                />
              </>
            ) : hasBooks === true ? (
              <CardList>
                {books &&
                  books?.map((data, ind) => {
                    return (
                      <CardRow
                        key={data.bookID}
                        onClick={() => {
                          if (parseInt(data.bookStatus) === 1)
                            navigate(`/app/bookedit?bookId=${data.bookID}`);
                          //navigate(`/app/bookedit?bookId=${data.id}`)
                        }}
                        style={{
                          cursor:
                            parseInt(data.bookStatus) === 1
                              ? "pointer"
                              : "auto",
                        }}
                      >
                        <CheckContent
                          style={{
                            background:
                              parseInt(data.bookStatus) === 1
                                ? "#ffffff"
                                : "inherit",
                            boxShadow:
                              parseInt(data.bookStatus) === 1
                                ? "0px 1px 1px rgba(7, 53, 98, 0.05), 0px 2px 2px rgba(7, 53, 98, 0.05)"
                                : "none",
                          }}
                        >
                          <CardHeader>
                            <ContentTitle>
                              {DateTime.fromISO(data.createdAt).toLocaleString(
                                DateTime.DATE_MED,
                              )}
                            </ContentTitle>
                            <ContentDetails>
                              {data.memoryCount} memories
                            </ContentDetails>
                          </CardHeader>
                          <StatusText>
                            {BookStatusToText[data.bookStatus]}
                          </StatusText>
                        </CheckContent>
                      </CardRow>
                    );
                  })}
              </CardList>
            ) : (
              "No books found"
            )}
          </CardBody>

          <CardFooter>
            <CardButton
              variant="primary"
              onClick={() => navigate(`/app/bookedit?bookId=0`)}
            >
              + New Book
            </CardButton>
          </CardFooter>
        </BookCard>
      </MainContainer>
    </BookSubmissionContainer>
  );
};

export default BookSubmission;
