/* eslint-disable no-restricted-globals */
import { Amplify } from "aws-amplify";
import { ConsoleLogger } from "aws-amplify/utils";
import React, { createContext, useEffect, useState } from "react";
import cognitoConfig from "../config/cognitoConfig";

import { getCurrentUser } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { useNavigate } from "react-router-dom";
const CognitoContext = createContext(null);

const CognitoProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isConfigured, setIsConfigured] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    ConsoleLogger.LOG_LEVEL = "DEBUG";

    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: cognitoConfig.userPool,
          userPoolClientId: cognitoConfig.clientId,
          loginWith: {
            oauth: {
              domain: cognitoConfig.userPoolUri,
              scopes: cognitoConfig.tokenScopes,
              redirectSignIn: [cognitoConfig.callbackUri],
              redirectSignOut: [cognitoConfig.signoutUri],
              responseType: "code",
            },
          },
        },
      },
    });

    setIsConfigured(true);

    const listener = Hub.listen("auth", ({ payload }) => {
      const { event } = payload;
      switch (event) {
        case "signInWithRedirect_failure":
          console.log("payload", payload);
          (payload.message ?? "")
            .split("+")
            .join(" ")
            .replace("PreSignUp failed with error", "")
            .replace("..", ".")
            .replace("%40", "@")
            .replace("%2C", ",");
          break;
        case "signedIn": {
          setCurrentUser(payload.data);
          break;
        }
        case "signIn_failure":
          console.log("user signin failures");

          break;
        case "cognitoHostedUI":
          break;
        case "oAuthSignOut": {
          console.log("user oauth signed out");
          break;
        }
        case "signedOut": {
          console.log("user signed out");
          break;
        }
        case "customOAuthState": {
          try {
            const { data } = payload;
            const plainText = decodeURIComponent(data);
            const json = JSON.parse(plainText);
            if (json?.redirectUrl) {
              navigate(json.redirectUrl);
              return;
            }
          } catch (error) {
            console.log("error redirecting", error);
            navigate("/app/bookedit");
          }
          break;
        }
        case "signInWithRedirect": {
          navigate("/app/bookedit");
          break;
        }
        default:
          console.log({ event, payload });
          break;
      }
    });

    getCurrentUser()
      .then((user) => {
        if (user) {
          if (window.clarity) {
            window.clarity(
              "identify",
              user.signInDetails?.loginId ?? user.userId,
            );
          }
          setCurrentUser(user);
        }
      })
      .catch((err) => {
        // user is not authenticated
      });

    return () => {
      listener();
    };
  }, []);
  return (
    <CognitoContext.Provider value={currentUser}>
      {isConfigured ? children : <></>}
    </CognitoContext.Provider>
  );
};
export default CognitoProvider;
